
.bouton_epreuve {
	//font-size: 1.4rem;
	color: $color_black !important;
}
.bouton_epreuve:hover {
	color: $color_white !important;
}

.btn_parcours {
	font-size: 1.6rem;
}

.index {
	h3 {
		font-weight: 900;
		font-style: italic;
	}

	.course_m { background: url('../images/bgcourse_course_m.jpg') no-repeat center center; background-size: cover; }
	.course_s { background: url('../images/bgcourse_course_s.jpg') no-repeat center center; background-size: cover; }
	.course_xs { background: url('../images/bgcourse_course_xs.jpg') no-repeat center center; background-size: cover; }
	.course_jeune8-11 { background: url('../images/bgcourse_course_jeune8-11.jpg') no-repeat center center; background-size: cover; }
	.course_jeune12-15 { background: url('../images/bgcourse_course_jeune12-15.jpg') no-repeat center center; background-size: cover; }
	.course_relais_m { background: url('../images/bgcourse_course_relais_m.jpg') no-repeat center center; background-size: cover; }
	.course_relais_s { background: url('../images/bgcourse_course_relais_s.jpg') no-repeat center center; background-size: cover; }

	.entete_accueil {
		min-height: 80vh;

		.contenu {
			padding-top: 100px;
			padding-bottom: 15vw;
			text-align: center;
			color: $color_white;
			font-weight: 700;

			.logo_chalain {
				display: block;
				margin: auto;
				margin-bottom: 0px;
				width: 200px;
			}

			h2 {
				font-weight: 700;
			}

			h1 {
				font-size: calc(3rem + 10vw);
				font-weight: 900;
				font-style: italic;

				&.smaller {
					font-size: calc(2rem + 5vw);
				}
			}

			p {
				margin-top: 20px;
				font-size: 1.3rem;
				letter-spacing: 0.1rem;
				font-weight: 900;
				font-style: italic;
			}

			.down_arrow {
				z-index: 10;
				display: block;
				

				img {
					width: 40px;
				}
			}
		}

		.overlay {
			position: absolute;
			z-index: -1;
			top: 0; left: 0; bottom: 0; right: 0;
			height: 100%;
			width: 100%;
			object-fit: contain;
		}

		.fondu {
			//display: none;
			position: absolute;
			z-index: -2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color_black, 0.4);
		}

		.fond {
			position: absolute;
			z-index: -3;
			top: 0; left: 0; bottom: 0; right: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.bouton_type_1 {
			background-color: $color_rose;
		}
	}

	.logo_edf {
		position: absolute;
		z-index: 10;
		top: -5vw;
		left: 50%;
		transform: translateX(-50%) translateY(-100%);
		max-width: 280px;

		@include media-breakpoint-down($responsive_breakpoint) {
			max-width: 200px;
			top: -3vw;
		}
	}

	.fluidblocs_container {
		display: flex;
		background-color: $color_gris;

		@include media-breakpoint-down($responsive_breakpoint) {
			flex-direction: column;
		}

		.fluidbloc {
			z-index: 0;
			position: relative;
			cursor: pointer;
			flex: 1;
			padding: $fluidblocs_vpad 20px;
			text-align: center;
			transition: $fluidbloc_transtime;

			p {
				margin: 0;
			}

			.surtitre {
				text-align: center;
				font-size: 2rem;
				font-weight: 900;
				font-style: italic;
			}
			.surtitre.decale {
				transform: translateX(-100px);
			}

			.bigletter {
				font-size: 6rem;
				line-height: 5rem;
				font-weight: 900;
				font-style: italic;
				margin-bottom: 10px;
			}

			.age {
				font-size: 2.8rem;
				line-height: 2.5rem;
				font-weight: 900;
				font-style: italic;
				margin-bottom: 10px;
				//white-space: nowrap;
			}

			.activation_button {
				margin-bottom: 14px;
				height: 1.5rem;

				.disable { display: none; }

				i {
					font-size: 1.5rem;
				}
			}

			.titre_info {
				font-weight: 700;
				font-style: italic;
			}

			.info {
				font-size: 2.3rem;
				line-height: 2.1rem;
			}

			.prix {
				margin-top: 20px;

				span {
					font-size: 1.8rem;
				}
			}

			.limite {
				margin: 30px 0;
			}

			.link_container {
				margin-bottom: 14px;
			}

			.link_reglement {
				color: $color_white;
			}

			.infobas {
				text-align: left;
				font-size: 0.9rem;
			}

			.hideable {
				z-index: 10;
				pointer-events: none;
				opacity: 0;
				transition: $fluidbloc_transtime;
			}

			.fondu {
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color_black, 0.65);
				transition: $fluidbloc_transtime;
			}
		}
		.fluidbloc.active {
			flex: 3;

			.activation_button {
				.disable { display: block; }
				.enable { display: none; }
			}

			.hideable {
				pointer-events: all;
				opacity: 1;
			}

			.fondu {
				background-color: rgba($color_black, 0.4);
			}
		}

		@include media-breakpoint-down($responsive_breakpoint) {
			.fluidbloc {
				max-height: 280px;
				padding: $fluidblocs_vpad 20px;
			}

			.fluidbloc:nth-child(2) {
				padding-top: 27vw;
			}

			.fluidbloc:nth-child(4) {
				padding-top: 17vw;
			}

			.fluidbloc.active {
				max-height: 1500px;
			}
		}
	}


	.actus {
		z-index: 2;
		padding: 0 $padding_sides;

		h2 {
			font-size: 2.7rem;
		}

		strong {
			font-size: 1.2rem;
			line-height: 1.7rem;
		}

		.gauche {
			transform: translateY(-50px);
		}

		@include media-breakpoint-down($responsive_breakpoint) {
			padding: 20px $padding_sides;

			h2 {
				font-size: 2.2rem;
			}

			.gauche {
				transform: translateY(-16px);
			}
		}

		p {
			line-height: 1.2rem;
		}
	}


	.dossards {
		z-index: 2;
		padding: 0 $padding_sides;
		max-height: 150px;
		.row { transform: translateY(-40px); }

		h2 {
			font-size: 3rem;
		}

		@include media-breakpoint-down($responsive_breakpoint) {
			max-height: initial;
			.row {
				flex-direction: column-reverse;
				transform: none;
				padding: 20px 0;
			}
			a { margin-top: 20px; }

			h2 {
				font-size: 2.3rem;
			}
		}

		* {
			margin: 0;
		}

		.gauche {
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}

		p {
			line-height: 1.2rem;
		}
	}

	.reglement {
		z-index: 2;
		padding: 0 $padding_sides;

		.gauche {
			transform: translateY(-100px);
		}
		.droite {
			transform: translateY(40px);
		}

		@include media-breakpoint-down($responsive_breakpoint) {
			padding: 20px $padding_sides;
			text-align: center;

			.gauche {
				transform: none;
				margin-bottom: 30px;
			}
			.droite {
				transform: none;
			}

			h3 {
				font-size: 1.4rem;
			}
		}
	}

	.courses_3 {
		.fluidbloc {
			padding-bottom: 30px;
		}
	}

	.partenaires {
		padding: 3vw 5vw;

		h3 {
			text-align: center;
		}

		.logos_container {
			display: flex;

			.logo_container {
				margin: 0 15px;

				img {
					width: 100%;
					height: auto !important;
				}
			}
		}

		img {
					width: 100%;
					height: auto !important;
				}
	}
}


.programme {
	h3 {
		font-size: 1.75rem;
		font-weight: 900;
		font-style: italic;
		margin: 0;
	}

	.entete_programme {
		min-height: 80vh;

		.contenu {
			padding-top: 100px;
			padding-bottom: 15vw;
			text-align: center;
			color: $color_white;
			font-weight: 700;

			.logo_chalain {
				display: block;
				margin: auto;
				margin-bottom: 0px;
				width: 160px;
			}

			h1 {
				font-size: calc(1rem + 10vw);
				font-weight: 900;
				font-style: italic;
			}

			.txt_container {
				padding: 0 $padding_sides;
				text-align: left;
			}

			p {
				font-weight: 500;
				margin: 0;
			}

			.down_arrow {
				z-index: 10;
				display: block;
				transform: translateY(50px);
				
				img {
					width: 40px;
				}
			}
		}

		.fond {
			position: absolute;
			z-index: -2;
			top: 0; left: 0; bottom: 0; right: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.overlay {
			position: absolute;
			z-index: -1;
			top: 0; left: 0; bottom: 0; right: 0;
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}

	.rappels {
		z-index: 2;
		padding: 0 $padding_sides;

		.date {
			font-size: 1.2rem;
			font-style: italic;
			margin: 0;
		}

		h2 {
			font-size: 3.5rem;
		}

		.bloc_reglement {
			transform: translateY(70px);
		}

		.gauche {
			transform: translateY(-50px);
		}
		.droite {
			//transform: translateY(40px);
		}

		@include media-breakpoint-down($responsive_breakpoint) {
			padding: 20px $padding_sides;
			text-align: center;

			.gauche {
				transform: none;
				margin-bottom: 30px;
			}
			.droite {
				transform: none;
			}

			.bloc_reglement {
				transform: none;
				margin-top: 60px;
			}

			a { margin-top: 20px; }

			h2 {
				font-size: 2.3rem;
			}
		}
	}

	.programme_container {
		color: $color_bleu;
		padding: $fluidblocs_vpad*0.85 20px;

		h2 {
			font-size: 3rem;
			text-align: right;
			transform: translateY(-70px);
		}

		.heure {
			color: $color_gris;
			font-size: 3rem;
			line-height: 3rem;
			font-weight: 900;
			font-style: italic;
			margin: 0;
		}

		.epreuve {
			font-size: 1.2rem;
			font-weight: 700;
			font-style: italic;
			margin-bottom: 25px;
		}
		
		.programme_col {

		}

		@include media-breakpoint-down($responsive_breakpoint) {
			h2 {
				margin: 30px 0;
				transform: none;
			}
		}
	}
}


.contact {

	// corrections interface générale
	footer .colonne._1 { display: none; }
	.boutons_hautdroite .bouton_inscription button { color: $color_white; background-color: $color_bleu; }
	.mot_menu { color: $color_bleu; }
	.navbar-toggler-icon .toggler-bar { background-color: $color_bleu; }

	//suite
	h1 {
		font-size: calc(1rem + 7vw);
		font-weight: 900;
		font-style: italic;
		color: $color_bleu;
		margin-bottom: 30px;
	}

	h3 {
		font-size: 1.75rem;
		font-weight: 900;
		font-style: italic;
		margin: 0;
	}

	.entete_contact {
		padding: 5vw;
		padding-top: 10vw;
		min-height: 80vh;
		text-align: center;
		color: $color_gris;

		a h3 {
			color: $color_bleu;
			margin-bottom: 30px;
		}
	}

	@include media-breakpoint-down($responsive_breakpoint) {
		.contenu {
			margin-top: 100px;
		}
	}
}