
// -----------------------------------
// ||            General            ||
// -----------------------------------

$responsive_breakpoint: 'lg';
$hauteur_triangles: 20vw;
$padding_sides: 10vw;
$fluidblocs_vpad: $hauteur_triangles;
$fluidbloc_transtime: 0.7s;

// Couleurs
$color_white: #ffffff;
$color_black: #000000;
$color_violet: #802985;
// $color_rose: #c32485;
$color_rose: #145575;
//$color_bleu: #36A9E1;
$color_bleu: #dc2b31;
// $color_bleu2: #555ea7;
$color_bleu2: #ccc5b5;
//$color_jaune: #FEEB15;
$color_jaune: #ccc5b5;
$color_gris: #9D9D9C;
$main_bg_color: $color_white;
$color_success: #008000;
$color_error: #a00000;


// Liens
$links_color: $color_black;
$links_weight: 700;
$links_decoration: underline;


// ----------------------------------
// ||            Navbar            ||
// ----------------------------------

// Général
$navbar_contained: 0; // Limiter la largeur des navlinks avec un container (0 ou 1)
$navbar_position: classic; // Mettre la navbar en fixed ou en classic. Valeurs : fixed_top, fixed_bottom, classic
$navbar_width: 100%; // En fixed, utiliser "auto" pour que la navcbar fasse la largeur minimum nécessaire
$navbar-padding-y: 0.6rem;
$navbar-padding-x: 1rem;
$navbar_bgcolor: #ffffff;
$navbar_bggradient: 0; // 0 pour désactiver le gradient. Synthaxe : linear-gradient(to right, color, color, color)
$navbar_shadow_height: 0; // 0 = désactive la shadow
$navbar_shadow_opacity: 0.3;
$navbar_links_align: left; // Valeurs : left, center, right

// Logo
$navbar_brand_height: 40px;
$navbar_brand_vertical_position: middle; // Valeurs : top, middle, bottom

// Apparence des liens
$navbar_links_fontsize: 0.9rem;
$navbar_links_fontweight: 500;
$navbar_links_color: $color_black;
$navbar_links_bgcolor: transparent;
$navbar_links_border_radius: 0px;
$navbar_links_padding-x: 0.25rem;
$navbar_links_padding-y: 0.25rem;
$navbar_links_margin: 0px 6px;
$navbar_links_forceuppercase: 1;
// Hover des liens
$navbar_links_color_hover: lighten($color_black, 10%);
$navbar_links_bgcolor_hover: transparent;
$navbar_links_border_radius_hover: 0px;
$navbar_links_animtime: 0.25s;

// Dropdowns
$navbar_dropdown_position: below; // above = par dessus le lien d'ouverture, below = calé sous le lien, 
$navbar_dropdown_bgcolor: #909090;
$navbar_dropdown_padding-x: 0;
$navbar_dropdown_padding-y: 0;
$navbar_dropdown_bordersize: 0;
$navbar_dropdown_bordercolor: transparent;
$navbar_dropdown_borderradius: 0;
$navbar_dropdown_shadow: none; // Synthaxe :  -6px 6px 12px rgba(0, 0, 0, 0.4)
$navbar_dropdown_link_color: #ffffff;
$navbar_dropdown_link_colorhover: #cccccc;
$navbar_dropdown_links_bghover: #505050;
$navbar_dropdown_links_forceuppercase: 1;
$navbar_dropdown_links_padding-x: 0.5rem;
$navbar_dropdown_links_padding-y: 1rem;
$navbar_dropdown_links_align: left; // Valeurs : left, right, center
$navbar_dropdown_animation_type: slide_fixed_title; // Types possibles : none, unfold (conseil: padding dropdown 0), opacify, slide, slide_fixed_title
$navbar_dropdown_animation_time: 0.5s;


// Nav underline (hover)
$navbar_underline_use: 1; // Activer ou désactiver l'underline (0 ou 1)
$navbar_underline_color: #a0a050;
$navbar_underline_popdirection: left; // Valeurs : left, right, center, bottom
$navbar_underline_width: 50%;
$navbar_underline_height: 4px;
$navbar_underline_animtime: 0.25s;

// Nav icons
$navbar_iconcontainer_animtime: 0.2s;
$navbar_icon_margin: 10px;
$navbar_icon_size: 1.1rem;
$navbar_icon_border_size: 20px;
$navbar_iconcontainer_border: 1px solid black;
$navbar_iconcontainer_borderradius: 1000px;
$navbar_iconcontainer_padding: 16px;
$navbar_iconcontainer_bgcolor: transparent;
$navbar_iconcontainer_border_hover: 1px solid black;
$navbar_iconcontainer_borderradius_hover: 1000px;
$navbar_iconcontainer_bgcolor_hover: rgba(255, 0, 0, 0.3);

// Toggler
$navbar_toggler_animation: 1; // Activer ou désactiver l'animation (0 ou 1)
$navbar_toggler_animtime: 0.2s;
$navbar-toggler-padding-y: 0.5rem;
$navbar-toggler-padding-x: 0.5rem;
$navbar_togglericon_size: 16px; // Taille de l'icone des 3 barres
$navbar_togglericon_width: 30px; // Valeurs : none si on veut un carré, taille si on veut spécifier une largeur
$navbar_toggler_borderradius: 0px;
$navbar_toggler_border: 0px solid transparent;
$navbar_toggler_bgcolor: transparent;
$navbar_toggler_position: left; // Valeurs : left, right
$navbar_toggler_distance: 10px;
$navbar_togglerbar_height: 4px;
$navbar_togglerbar_color: $color_white;

// Comportement en mode collapsed
$navbar_collapse_breakpoint: xl; // xl = always collapsed (attention, changer aussi dans l'html)
$navbar_collapse_nobar: 1; // Afficher la version collaspe sans barre de nav, avec juste le toggle (0 ou 1)
$navbar_collapse_direction: vertical; // Valeurs : horizontal, vertical (penser à changer la class "width" sur navbar-collapse dans l'html)
$navbar_collapse_position: right; // Valeurs : top, bottom, left, right
$navbar_collapse_height: 100vh;
$navbar_collapse_width: 100%;
$navbar_collapse_bgcolor: $color_jaune; // Valeurs : couleur, keep (garder la couleur non collapse)
$navbar_collapse_content_vertical_align: top; // Valeurs : top, center, bottom
$navbar_collapse_content_horizontal_align: center; // Valeurs : left, center, right
$navbar_collapse_col_padding: 5vw;
$navbar_collapse_etage_padding: 0;
$navbar_collapse_keep_underline: 0; // 0 ou 1
$navbar_collapse_links_color: $color_black; // Valeurs : couleur, keep (garder la couleur non collapse)
$navbar_collapse_links_fontsize: 1.4rem;
$navbar_collapse_links_fontweight: 900;
$navbar_collapse_links_centering: left; // Valeurs : left, right, center
$navbar_collapse_opened_dropdown: 1; // 0 ou 1
$navbar_collapse_dropdown_decalage: 30px;

// Variables boostrap à réinitialiser
$navbar-brand-padding-y: 0;


// ---------------------------------
// ||        Cookie notice        ||
// ---------------------------------

$cookie_notice_type: page_top; // Valeurs : fixed, page_top
$cookie_notice_position: top; // Valeurs : top, bottom
$cookie_notice_animation: unfold; // Valeurs : slide, unfold, opacity
$cookie_notice_animation_time: 0.3s;
$cookie_notice_padding: 10px 20px;
$cookie_notice_txtcolor: #ffffff;
$cookie_notice_bgcolor: #999999;
$cookie_notice_button_txtcolor: #ffffff;
$cookie_notice_button_bgcolor: #909000;
$cookie_notice_button_bgcolor_hover: #b0b000;
$cookie_notice_link_decoration: underline;



// ---------------------------------
// ||            Forms            ||
// ---------------------------------

$form_label_fontsize: 1rem;
$form_label_fontweight: 500;
$form_label_fontstyle: none;
$form_label_marginbottom: 0px;
$form_label_paddingleft: 0px;
$form_radiochecklabel_fontsize: 1rem;
$form_radiochecklabel_fontweight: 500;
$form_radiochecklabel_fontstyle: none;
$form_radiochecklabel_marginbottom: 0px;
$form_radiochecklabel_paddingleft: 1.25rem;
$form_input_padding: 0.375rem 0.75rem;
$form_input_border: none;
$form_input_border_green: 2px solid $color_success;
$form_input_border_red: 2px solid $color_error;
$form_input_borderradius: 0;
$form_input_fontcolor: $color_black;
$form_input_bgcolor: $color_white;
$form_input_fontsize: 1rem;
$form_input_fontweight: 500;
$form_input_fontstyle: none;
$form_input_marginbottom: 0px;
$form_input_placeholder_color: pink;



// ----------------------------------
// ||            Footer            ||
// ----------------------------------

$footer_padding: 4vw;
$footer_bgcolor: $color_gris;
$footer_separateur_horizontal: 20px;
$footer_separateur_vertical: 30px;
$footer_txtcolor: $color_black;
$footer_link_color: $color_black;
$footer_link_hover_color: $color_black;
$footer_link_underline: 1; // 0 ou 1