
// ------------------------------------
// ||             Modals             ||
// ------------------------------------

#modal_annonce {
    position: fixed;
    z-index: 10000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 250px;
    max-width: 700px;
    background-color: #fff;
    text-align: left;
    pointer-events: none;
    opacity: 0;
    transition: 0.5s;
    @include media-breakpoint-down($responsive_breakpoint) {
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }

    .close {
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 10px;
        border-radius: 0;
        border: none;
        font-size: 3rem;
        color: grey;
        background-color: transparent;
        padding: 0;
    }

    img {
        width: 100%;
    }

    .main_zone {
        padding: 40px;
        //padding-top: 0;
        background-color: $color_white;

        .titre {
            font-weight: 800;
            line-height: 0.8;
            text-align: left !important;
            margin-bottom: 30px;

            .soustitre {
                font-weight: 500;
                font-size: 1.5rem;
                color: $color_bleu;
            }
        }

        .button_container {
            margin-top: 30px;
            text-align: center;

            a {
                display: inline-block;
            }
        }

        .text_bas_container {
            p {
                font-size: 0.95rem;
                color: $color_bleu;
                line-height: 1rem;
            }
        }
    }

    p:last-child {
        margin: 0;
    }
}

#modal_annonce.show {
    pointer-events: all;
    opacity: 1;
}

.back_modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    pointer-events: none;
}

.back_modal.show {
    pointer-events: all;
    opacity: .5;
}



// GÉNÉRAL
.modal {
    padding-right: 0px !important;
}

.modal.centered {
   .modal-dialog {
        margin: 0 !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    } 
}


// MODALE MINIMALISTE, BORDERLESS ETC
.modal.bare {
    text-align: center;

    .modal-dialog {
        display: inline-block;
    	
    	.modal-content {
    		border-radius: 0 !important;
    		border: none;
            min-height: 40px;

    		button.close {
    			cursor: pointer;
    			position: absolute;
    			z-index: 10;
    			top: 10px;
    			right: 10px;
    			font-size: 3rem;
    			line-height: 0.5em;
    			color: $color_black;
    		}

    		.modal-body {
    			padding: 0;
    		}
    	}
    }
}

// MODALE AVEC CAROUSEL DEDANS
.carousel_modal {

	.modal-dialog {
		height: auto !important;
		max-width: 90vw;

		.modal-content {
			height: 100%;

            img {
                max-height: 90vh;
            }
		}
	}
}


// Modale confirmation contact
.form_error_box {
    .modal-title {
        font-weight: 700;
    }

    .form_modal_ok {
        color: $color_success;
    }

    .form_modal_fail {
        color: $color_error;
    }

    i {
        float: left;
        margin-right: 16px;
        font-size: 3rem;
        margin-bottom: 16px;
    }

    h6 {
        margin-top: 26px;
        font-weight: 700;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            border-top: 1px solid #e0e0e0;
            font-size: 0.9rem;
            padding-top: 8px;
            margin-bottom: 10px;
        }
    }
}
