
// -----------------------------------
// ||           Carousels           ||
// -----------------------------------


// -------------- Options --------------
$carousel_id: 'carousel_partenaires';
$carousel_height: 40vw;
$carousel_width: 100%;
$carousel_control_width: 20px;
$carousel_control_size: 2.5rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 0px;
$carousel_indicators_bottom_distance: 30px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_black, 0);
$carousel_indicators_border: 1px solid $color_black;
$carousel_indicators_borderradius: 1000px;
$carousel_indicators_active_color: rgba($color_black, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_black, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_black, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------

.carousel.ordinateur {
	.logo {
		flex: 0 0 20%;
		max-width: 20%;
	}
}

.carousel.mobile {
	.logo {
		flex: 0 0 33%;
		max-width: 33%;
	}
}

.carousel.ordinateur { display: block; }
.carousel.mobile { display: none; }
@include media-breakpoint-down($responsive_breakpoint) {
	.carousel.ordinateur { display: none; }
	.carousel.mobile { display: block; }
}


##{$carousel_id}, #carousel_partenaires_mobile {
	.carousel-inner {
		
		.carousel-item {		
			//display: flex;

			.logo {
				position: relative;
				display: block;
				//flex: 1;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		color: $color_black;
		text-decoration: none;
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev { font-size: $carousel_control_size; transform: translateX(-35px); }
	.carousel-control-next { font-size: $carousel_control_size; transform: translateX(35px); }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}