
// ----------------------------------
// ||            Footer            ||
// ----------------------------------


// ----------------- CSS SPECIFIQUE AU SITE -----------------

.triangle_container.footer {
	transform: translateY(3px);	
}

footer {
	.colonne._1 {
		text-align: center;
		margin-bottom: 50px;
	}

	.colonne._2 {
		img {
			max-width: 200px;
			margin: auto;
		}

		.adresse {
			margin-top: 30px;
			color: $color_white;
			text-align: center;
			font-size: 1.1rem;
			font-weight: 700;
			font-style: italic;
		}

		a {
			text-align: center;
		}
	}

	.colonne._3 {
		display: flex;

		a {
			display: flex;
			text-decoration: none;

			span {
				font-size: 1rem;
				font-weight: 900;
				font-style: italic;
				color: $color_white;

				img {
					max-width: 40px;
					margin-left: 10px;
					transform: translateY(-6px);
				}
			}
		}

		.bloc._1 {
			margin-right: 20px;
		}
	}

	.rectangle_link {
		color: $color_white;
		font-size: 0.9rem;
	}
	.rectangle_link:hover {
		color: $color_white;
	}
}




// ----------------------------------------------------------


// FOOTER - GÉNÉRAL
footer {
	z-index: -1;
	background-color: $footer_bgcolor;
	padding: $footer_padding;
	.separateur { width: $footer_separateur_horizontal; }
	color: $footer_txtcolor;
	a {
		color: $footer_link_color;
		@if $footer_link_underline == 1 { text-decoration: underline; }
	}
	a:hover {
		color: $footer_link_hover_color;
	}
}


@include media-breakpoint-down($responsive_breakpoint) {

	footer {
		.separateur { width: 100%; height: $footer_separateur_vertical; }

		.colonne._3 {
			margin-top: 40px;
			justify-content: center;
		}
	}

}