
// ---------------------------------
// ||            Forms            ||
// ---------------------------------

form {
	label {
		font-size: $form_label_fontsize;
		font-weight: $form_label_fontweight;
		font-style: $form_label_fontstyle;
		margin-bottom: $form_label_marginbottom;
		padding-left: $form_label_paddingleft;
	}

	.form-check-label {
		font-size: $form_radiochecklabel_fontsize;
		font-weight: $form_radiochecklabel_fontweight;
		font-style: $form_radiochecklabel_fontstyle;
		margin-bottom: $form_radiochecklabel_marginbottom;
		padding-left: $form_radiochecklabel_paddingleft;
	}

	.form-control {
		padding: $form_input_padding;
		border: $form_input_border;
		border-radius: $form_input_borderradius;
		color: $form_input_fontcolor;
		background-color: $form_input_bgcolor;
		font-size: $form_input_fontsize;
		font-weight: $form_input_fontweight;
		font-style: $form_input_fontstyle;
		margin-bottom: $form_input_marginbottom;
	}
	.form-control.green { border: $form_input_border_green; }
	.form-control.red { border: $form_input_border_red; }

	.form-control::placeholder { color: $form_input_placeholder_color; }
	.form-control:-ms-input-placeholder { color: $form_input_placeholder_color; }
	.form-control::-ms-input-placeholder { color: $form_input_placeholder_color; }
}