
// ----------------------------------
// ||            Global            ||
// ----------------------------------

html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
}

body {
	background-color: $main_bg_color;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
}

section {
	position: relative;
	width: 100%;
}

section a {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}
/*section a:hover {
	text-decoration: $links_decoration;
}*/

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
div[type="submit"],
*[type="submit"],
button, 
input {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

.full_site {
	position: relative;
	min-height: 100vh;
}

.hidden {
	display: none;
}

.admin_link {
	position: absolute;
	z-index: 10000;
	background-color: transparent;
	border-top: 26px solid #343a40;
	border-left: 26px solid #343a40;
	border-right: 26px solid transparent;
	border-bottom: 26px solid transparent;
	top: -36px;
	left: -36px;
	animation-name: admin_link_start;
    animation-duration: 0.8s;
	transition: 0.3s;

	i {
		color: white;
		position: absolute;
		top: -20px;
		left: -20px;
	}
}
.admin_link:hover {
	top: 0px;
	left: 0px;
}
@keyframes admin_link_start {
    from {top: 0px;left: 0px;}
    to {top: -36px;left: -36px;}
}

.grecaptcha-badge { display: none; }

* {
	font-family: "interface", sans-serif;
}

.anchor {
	pointer-events: none;
	display: block;
	position: absolute;
	left: 0;
	top: -150px;
	width: 100%;
	height: 340px;
	//background-color: blue;
}
.anchor_bas {
	pointer-events: none;
	display: block;
	position: absolute;
	top: 100px;
	width: 100%;
	height: 78%;
	//background-color: red;
}
#entete.anchor_bas {
	top: 0;
}

.scrollnav {
	position: fixed;
	z-index: 100;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 40px;
	/* background-color: rgba($color_white, 0.2); */

	.scrollnav_link {
		height: 12px;
		width: 12px;
		background-color: rgba($color_white, 0);
		margin: 20px 50%;
		border-radius: 1000px;
		margin-left: 50%;
		transform: translateX(-50%);
		border: 1px solid $color_white;
	}
	.scrollnav_link:hover {
		background-color: rgba($color_white, 0.3);
	}
	.scrollnav_link.active {
		background-color: rgba($color_white, 1);
	}
}

// ----------------- CSS SPECIFIQUE AU SITE -----------------

.txtbleu { color: $color_bleu !important; }
.txtgris { color: $color_gris !important; }
.txtblanc { color: $color_white !important; }
.txtrose { color: $color_rose !important; }
.txtbleu2 { color: $color_bleu2 !important; }
.bgrose { background-color: $color_rose !important; }
.bgbleu { background-color: $color_bleu !important; }
.bgbleu2 { background-color: $color_bleu2 !important; }
.bggris { background-color: $color_gris !important; }

.xbold_italic {
	font-weight: 900;
	font-style: italic;
}

span.gros {
	font-size: 2rem;
}

.triangle_container {
	position: relative;
}
.triangle {
	pointer-events: none;
	position: absolute;
	z-index: 1;
	width: 100%;
	border-left: 50vw solid transparent;
	border-right: 50vw solid transparent;
	border-top: $hauteur_triangles/2 solid transparent;
	border-bottom: $hauteur_triangles/2 solid transparent;
}
.triangle.basgauche { bottom: 0; left: 0; }
.triangle.basdroite { bottom: 0; right: 0; }
.triangle.basgauche.bleu {
	border-left: 50vw solid $color_bleu;
	border-bottom: $hauteur_triangles/2 solid $color_bleu;
}
.triangle.basdroite.bleu {
	border-right: 50vw solid $color_bleu;
	border-bottom: $hauteur_triangles/2 solid $color_bleu;
}
.triangle.hautgauche.bleu {
	border-left: 50vw solid $color_bleu;
	border-top: $hauteur_triangles/2 solid $color_bleu;
}
.triangle.hautdroite.bleu {
	border-right: 50vw solid $color_bleu;
	border-top: $hauteur_triangles/2 solid $color_bleu;
}
.triangle.basgauche.rose {
	border-left: 50vw solid $color_rose;
	border-bottom: $hauteur_triangles/2 solid $color_rose;
}
.triangle.hautdroite.rose {
	border-right: 50vw solid $color_rose;
	border-top: $hauteur_triangles/2 solid $color_rose;
}
.triangle.basgauche.bleu2 {
	border-left: 50vw solid $color_bleu2;
	border-bottom: $hauteur_triangles/2 solid $color_bleu2;
}
.triangle.hautdroite.bleu2 {
	border-right: 50vw solid $color_bleu2;
	border-top: $hauteur_triangles/2 solid $color_bleu2;
}
.triangle.basgauche.gris {
	border-left: 50vw solid $color_gris;
	border-bottom: $hauteur_triangles/2 solid $color_gris;
}


.bouton_type_1 {
	user-select: none;
	outline: none !important;
	cursor: pointer;
	display: inline-block;
	width: auto;
	text-decoration: none;
	font-size: 1.3rem;
	font-weight: 900;
	font-style: italic;
	padding: 8px 30px;
	border-radius: 100px;
	color: $color_white;
	background-color: $color_bleu;
	border: none;
}
.bouton_type_1:hover {
	text-decoration: none;
	color: $color_white;
	background-color: $color_black;
}

.bouton_type_2 {
	user-select: none;
	outline: none !important;
	cursor: pointer;
	display: inline-block;
	width: auto;
	text-decoration: none;
	font-size: 1.1rem;
	letter-spacing: 0.04rem;
	font-weight: 900;
	font-style: italic;
	padding: 4px 30px;
	border-radius: 100px;
	color: $color_gris;
	background-color: $color_white;
	border: none;
}
.bouton_type_2:hover {
	text-decoration: none;
	color: $color_white;
	background-color: $color_black;
}
.bouton_type_2:disabled {
	cursor: initial;
	color: $color_gris;
	background-color: lighten($color_gris, 20%);
}

.bouton_type_3 {
	user-select: none;
	outline: none !important;
	cursor: pointer;
	display: inline-block;
	width: auto;
	text-decoration: none;
	font-size: 1.1rem;
	letter-spacing: 0.04rem;
	font-weight: 900;
	font-style: italic;
	padding: 4px 30px;
	border-radius: 100px;
	color: $color_bleu;
	background-color: $color_white;
	border: none;
}
.bouton_type_3:hover {
	text-decoration: none;
	color: $color_white;
	background-color: $color_black;
}

.bouton_type_4 {
	user-select: none;
	outline: none !important;
	cursor: pointer;
	display: inline-block;
	width: auto;
	text-decoration: none;
	font-size: 1.1rem;
	letter-spacing: 0.04rem;
	font-weight: 900;
	font-style: italic;
	padding: 4px 30px;
	border-radius: 100px;
	color: $color_white;
	background-color: transparent;
	border: 1px solid $color_white;
}
.bouton_type_4:hover {
	text-decoration: none;
	color: $color_white;
	background-color: $color_black;
}



.carte_googlemaps h2 {
	margin: 0;
	margin-top: 20px;
	padding: 6px;
	font-size: 1.75rem;
	font-weight: 900;
	font-style: italic;
	color: $color_black;
	text-align: center;
	color: $color_white;
	background-color: $color_gris;
}

#carte_googlemaps {
    height: 80vh;
    width: 100%;
}


// ----------------------------------------------------------